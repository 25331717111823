import React, { useState } from 'react';
import i18n from "../../../i18n";
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import Layout from '../../components/Layout';
import CoverImage from '../../components/CoverImage';
import SignUpForm from '../../components/Forms/signupForm'
import signUpMobile from '../../../assets/signupMobile.png';
import signUpDesktop from '../../../assets/signUpDesktop.png';
import { signupLaunched } from '../../state/reducer';
import generateMeta from '../../utils/meta';

const SignupPage = ({ data, pageContext, location }) => {
  // SEO
  const initMetas = [{
    name: 'og:url',
    content: `${process.env.GATSBY_WEBSITE_URL}${pageContext.currentPath}`,
  }];

  const metas = generateMeta(initMetas, data?.markdownRemark?.frontmatter);

  // used to redirect user after signup 
  let from;
  if (location?.state?.from) { from = location.state.from };

  // i18n used for translation
  i18n(pageContext.locale);

  // used for navBarMenu
  const sortedFirstLevelCategorySlugs = ["femme", "enfant", "homme", "coaching-capillaire"];

  const [formValue, setFormValue] = useState({
    email: null,
    password: null,
    from: from
  });

  const dispatch = useDispatch();
  const signup = () => {
    dispatch(signupLaunched(formValue));
  };

  return (
    <Layout
      lang={pageContext.locale}
      currentPath={pageContext.currentPath}
      firstLevelCategories={data.menuFirstLevel.edges.sort((firstLevelCategory1, firstLevelCategory2) => {
        return (
          sortedFirstLevelCategorySlugs.indexOf(firstLevelCategory1.node.slug) - sortedFirstLevelCategorySlugs.indexOf(firstLevelCategory2.node.slug)
        )
      })}
      secondLevelCategories={data.menuSecondLevel.edges}
      thirdLevelCategories={data.menuThirdLevel.edges}
    >
      <Helmet>
        <html lang="fr" />
        <title>Inscription</title>
        {metas && metas.map(meta => (
          <meta key={meta.name} name={meta.name} content={meta.content} />
        ))}
      </Helmet>
      <>
        <CoverImage
          imageDesktop={signUpDesktop}
          imageMobile={signUpMobile}
          title="Inscription"
          altDesktop="palm trees"
          altMobile="palm trees"
        />
      </>

      <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <SignUpForm
          formValue={formValue}
          setFormValue={setFormValue}
          submit={() => signup()}
          lang={pageContext.locale}
        />
      </div>
    </Layout>
  );
};

export default SignupPage;

export const query = graphql`
  query( $locale: String! ) {
    menuFirstLevel: allContentfulCategorySve(filter: { slug: { in: ["femme", "enfant", "homme", "coaching-capillaire"] }, node_locale: {eq: $locale}}) {
      edges {
        node {
          slug
          categoryName
        }
      }
    }
    menuSecondLevel: allContentfulCategorySve(filter: {parentCategory: { slug: { in: ["femme", "enfant", "homme", "coaching-capillaire"] }, node_locale: {eq: $locale}}}
    sort : {fields: [slug], order: ASC}) {
      edges {
        node {
          slug
          categoryName
          categoryEncartTitle
          parentCategory {
            slug
            categoryName
            categoryEncartTitle
          }
        }
      }
    }
    menuThirdLevel: allContentfulCategorySve(filter: {parentCategory: {parentCategory: { slug: { in: ["femme", "enfant", "homme", "coaching-capillaire"] }, node_locale: {eq: $locale}}}}
    sort : {fields: [slug], order: ASC}) {
      edges {
        node {
          slug
          categoryName
          categoryEncartTitle
          parentCategory {
            slug
            categoryName
            categoryEncartTitle
            parentCategory {
              slug
              categoryName
              categoryEncartTitle
            }
          } 
        }
      }
    }
    allContentfulCategorySve(filter: { node_locale: { eq: $locale } }) { 
      edges {
        node {
          categoryName
          categoryEncartTitle
          slug
          parentCategory {
            categoryName
            categoryEncartTitle
          }
        }
      }
    }
    markdownRemark(fileAbsolutePath: {regex: "/signup.md/"}) {
      html
      frontmatter {
          title
          description
          ogtitle
          ogdescription
      }
    }
  }
`;
