import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withTranslation } from "react-i18next";
import * as Yup from "yup";
import { Field, Formik, Form, ErrorMessage } from 'formik';
import { capitalizeFirst } from '../../../utils/format';
import { useStyles } from "react-styles-hook";
import getStyles from '../styles';
import { Link } from 'gatsby';
import { getUsersEmailLaunched, signupLaunched } from '../../../state/reducer';

const SignUpForm = ({ formValue, setFormValue, lang }) => {
  const classes = useStyles(getStyles());
  const dispatch = useDispatch();

  const initialValues = {
    email: formValue.email,
    password: formValue.password,
    confirmPassword: "",
  };

  // check if email already exist in user Table
  const [formEmail, setFormEmail] = useState();
  const [errorMessage, setErrorMessage] = useState(null);

  const { usersEmail, getUsersEmailLoading, signupLoading, isAuthenticating } = useSelector((state) => (
    {
      usersEmail: state.getIn(['root', 'usersEmail']),
      getUsersEmailLoading: state.getIn(['root', 'getUsersEmailLoading']),
      signupLoading: state.getIn(['root', 'signupLoading']),
      isAuthenticating: state.getIn(['root', 'isAuthenticating'])
    }
  ));

  useEffect(() => {
    if (usersEmail && getUsersEmailLoading === false) {
      if (usersEmail.length === 0) {
        dispatch(signupLaunched(formValue));
      } else {
        setErrorMessage("Cette adresse e-mail est déjà utilisée.");
      }
    }
  }, [getUsersEmailLoading]);

  const signup = () => {
    dispatch(getUsersEmailLaunched({ formEmail }));
  };

  // Form Validation Schema (les traductions automatiques crash l'appli)
  // const ValidationSchema = Yup.object().shape({
  //   email: Yup.string()
  //     .required(t("emailRequired"))
  //     .email(t("emailType"))
  //     .nullable(),
  //   password: Yup.string()
  //     .required(t("passwordRequired"))
  //     .matches(
  //       /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$&+,:;=?@#|'<>.^*()%!-])[A-Za-z\d$&+,:;=?@#|'<>.^*()%!-]{8,}$/,
  //       t("passwordType")
  //     )
  //     .nullable(),
  //   confirmPassword: Yup.string()
  //     .required(t("confirmPasswordRequired"))
  //     .oneOf([Yup.ref('password'), null], t("passwordMismatch"))
  //     .nullable()
  // });
  const ValidationSchema = Yup.object().shape({
    email: Yup.string()
      .required("Veuillez saisir votre email")
      .email("Veuillez renseigner un format e-mail valide")
      .nullable(),
    password: Yup.string()
      .required("Veuillez saisir votre mot de passe")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$&+,:;=?@#|'<>.^*()%!-])[A-Za-z\d$&+,:;=?@#|'<>.^*()%!-]{8,}$/,
        "Veuillez saisir au moins 8 caractères, une lettre, un chiffre, une majuscule et un caractère spécial tel que $&+,:;=?@#|'<>.^*()%!-"
      )
      .nullable(),
    confirmPassword: Yup.string()
      .required("Confirmation de mot de passe requis")
      .oneOf([Yup.ref('password'), null], "Les mots de passe ne correspondent pas")
      .nullable()
  });

  return (
    <div className="sm:mx-auto sm:w-full sm:max-w-lg">
      <div className="w-full mx-auto">
        <Formik
          initialValues={initialValues}
          validationSchema={ValidationSchema}
          onSubmit={signup}
        >
          {({ errors, touched, values, handleChange }) => (
            <Form className="mx-auto w-5/6 flex flex-col justify-center items-center">
              <div className="w-full flex flex-col md:flex-row justify-between items-start md:items-center mb-6">
                <div className="w-full">
                  <label className="pb-2 block text-s text-grey font-medium">{capitalizeFirst("e-mail")}</label>
                  <Field
                    id="email"
                    name="email"
                    type="email"
                    value={values.email ? values.email : ""}
                    onChange={(e) => {
                      handleChange(e);
                      setFormValue((prevState) => ({
                        ...prevState,
                        [e.target.name]: e.target.value.toLowerCase()
                      }));
                      setFormEmail(e.target.value.toLowerCase());
                    }}
                    style={classes.roundedBorder}
                    className={`w-full ${errors.email && touched.email ? 'border-red focus:border-red' : 'border-grey-light focus:outline-none focus:ring-orange-light focus:border-orange-dark'}`}
                  />
                  {errors.email && touched.email && (
                    <p
                      style={classes.error}
                      className="px-2 pt-2 text-center md:text-left text-orange-chocolate"
                    >
                      {errors.email}
                    </p>
                  )}
                </div>
              </div>

              <div className="w-full flex flex-col md:flex-row justify-between items-start md:items-center mb-4 md:mb-6">
                <div className="w-full">
                  <label className="pb-2 block text-s font-medium text-grey">{capitalizeFirst("mot de passe")}</label>
                  <Field
                    id="password"
                    name="password"
                    type="password"
                    value={values.password}
                    onChange={(e) => {
                      handleChange(e);
                      setFormValue((prevState) => ({
                        ...prevState,
                        [e.target.name]: e.target.value
                      }));
                    }}
                    style={classes.roundedBorder}
                    className={`w-full ${errors.password && touched.password ? 'border-red focus:border-red' : 'border-grey-light focus:outline-none focus:ring-orange-light focus:border-orange-dark'}`}
                  />
                  {errors.password && touched.password && (
                    <p
                      style={classes.error}
                      className="px-2 pt-2 text-center md:text-left text-orange-chocolate"
                    >
                      {errors.password}
                    </p>
                  )}
                </div>
              </div>

              <div className="w-full flex flex-col md:flex-row justify-between items-start md:items-center mb-2 md:mb-4">
                <div className="w-full">
                  <label className="pb-2 block text-s font-medium text-grey">{capitalizeFirst("confirmation de mot de passe")}</label>
                  <Field
                    id="confirmPassword"
                    name="confirmPassword"
                    type="password"
                    value={values.confirmPassword}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    style={classes.roundedBorder}
                    className={`w-full ${errors.confirmPassword && touched.confirmPassword ? 'border-red focus:border-red' : 'border-grey-light focus:outline-none focus:ring-orange-light focus:border-orange-dark'}`}
                  />
                  {errors.confirmPassword && touched.confirmPassword && (
                    <p
                      className="px-2 pt-2 text-center md:text-left"
                      style={classes.error}
                    >
                      {capitalizeFirst(errors.confirmPassword)}
                    </p>
                  )}
                </div>
              </div>

              <div className="flex justify-end pt-3">
                <button
                  type="submit"
                  className="mt-4 md:mt-0 text-white bg-orange-dark hover:bg-orange-chocolate border border-transparent rounded-lg hover:border-0 py-2 px-4 focus:outline-none focus:ring-0"
                  disabled={signupLoading || isAuthenticating}
                >
                  {(signupLoading || isAuthenticating) ? "Chargement..." : "S'inscrire"}
                </button>
              </div>
              {/* error message */}
              <div className='my-4'>
                {(errorMessage)
                  && (
                    <p style={classes.error}>{errorMessage}</p>
                  )}
              </div>
              <div className="flex flex-col items-center pt-5">
                <p>Vous êtes déjà inscrit ? </p>
                <Link to={`/${lang}/login`} className="pt-1 underline">Se connecter</Link>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default SignUpForm;
