const getStyles = (theme) => ({
  form: {
    width: "100%",
  },
  formControl: {
    width: "100%",
    height: ({ id }) => (id !== "message" ? 95 : 220),
  },
  input: {
    background: '#fff',
    border: '1px solid #707070',
  },
  helper: {
    color: '#fff',
    marginLeft: 0,
  },
  helperText: {
    marginLeft: 3,
    marginTop: 3, // align text with icon
  },
  roundedBorder: {
    borderRadius: 5
  },
  error: {
    color: "#ff0000"
  }
});

export default getStyles;
